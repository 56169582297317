<template>
    <div class="SetPromotionAdd">
        <div class="SetPromotionAddHeader">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>营销工具</el-breadcrumb-item>
                <el-breadcrumb-item>套装促销</el-breadcrumb-item>
            </el-breadcrumb>
            <p>创建套装促销</p>
        </div>
        <div class="SetPromotionAddData">
            <div class="bannerHeader">
                <div class="bannerHeaderOne">
                    <span class="isActive">1</span>
                    <b>选择商品</b>
                </div>
                <img v-if="isActive === 1" style="height: 14px;" src= "./../../../../assets/images/serviceData/xian.png" alt="">
                <img v-if="isActive === 2" style="height: 14px;" src= "./../../../../assets/images/serviceData/chengxian.png" alt="">
                <div class="bannerHeaderOne">
                    <span :class="isActive === 2 ? 'isActive' : ''">2</span>
                    <b>设置商品优惠券</b>
                </div>
            </div>
        </div>
        <div class="SetPromotionAddFill">
            <div class="SetPromotionOne">
                <div class="SetPromotionOneHeader">
                    <div class="title">基础规则</div>
                    <div class="menuName">
                        <span>促销名称</span>
                        <el-input v-model="suitDetail.name" style="width: 300px;margin-left: 10px"></el-input>
                    </div>
                    <div class="menuShop">
                        <span>促销商品</span>
                        <i class="iconfont">&#xe72a;</i>
                        <span class="span">套装</span>
                        <span class="span" style="margin-left: 70px">至少设置2个商品为必买品</span>
                    </div>
                </div>
                <div class="SetPromotionOneTable" style="position: relative">
                    <span style="position: absolute;right: 40px;top:13px;z-index: 999">套装必买商品</span>
                    <el-table row-key="goods_data.id" :data="suitDetail.activity_data" @select="selectOne" @selection-change="handleSelectionChange" ref="multipleTable"
                              style="width: 100%;flex: 1;margin-top: 20px;border: 1px solid #DCDCDC"
                              :cell-style="{height: '100px',color:'#333333', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:500}"
                              :header-cell-style="{fontWeight: '500', color: '#666666', background: '#F5F5F5',height: '46px'}">
                        <el-table-column label="商品名称" width="440" align="">
                            <template slot-scope="scope">
                                <div class="shopData">
                                    <img :src="scope.row.goods_data.image" alt="">
                                    <span>{{scope.row.goods_data.name}} <b>在售</b></span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品价" prop="goods_data.price" align="right"></el-table-column>
                        <el-table-column label="优惠金额" align="center">
                            <template slot-scope="scope">
<!--                                <el-input class="redInput" :class="{'is-error': scope.row.errorPrompt}" @input="watchDiscounts($event, scope.row.goods_data.id)"-->
<!--                                          oninput="value=value.replace(/[^\d]/g,'')"-->
<!--                                          style="width: 100px;" v-model.number="scope.row.yh_sum"></el-input>-->
                                <el-input-number class="redInput" :class="{'is-error': scope.row.errorPrompt}" @input="watchDiscounts($event, scope.row.goods_data.id)"
                                                 v-model="scope.row.yh_sum" :min="0" :max="scope.row.goods_data.price" :precision="2"
                                                 :controls="false" style="width: 100px;"></el-input-number>
                            </template>
                        </el-table-column>
<!--                        为 true 则会在数据更新之后保留之前选中的数据（需指定 row-key）-->
                        <el-table-column reserve-selection label="套装必买品" type="selection" width="100" align="left"></el-table-column>
                    </el-table>
                </div>
                <div class="SetPromotionOneText">
                    <span></span>
                    <div class="text">
                        <div class="text1">
                            <span>套装促销价</span>
                            <span>￥</span>
                            <span>{{promotionNum}}</span>
                        </div>
                        <div class="text2">
                            共计优惠{{discountsNum}}元
                        </div>
                    </div>
                </div>
                <div class="SetPromotionOneBtn">
                    <el-button class="btn" @click="toBack">取消</el-button>
                    <el-button class="btn btn-red" type="danger" :disabled="isBtn" @click="toNext">提交</el-button>
                    <span>备注：最少选择两个商品才可以提交！</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    export default {
        data(){
            return {
                suitId: Number(this.$route.query.id) || null,
                suitDetail: {
                    type: 3,
                    name: '',
                    activity_data: []
                },
                tableData:[
                    // {
                    //     shopName:'阿迪达斯官网adidas DURAMO 9男鞋跑步运动鞋BB7066黑色',
                    //     imgSrc:'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=98240891,2735753190&fm=11&gp=0.jpg',
                    //     jdPrice:210.00,
                    //     discountsPrice:''
                    // },
                    // {
                    //     shopName:'阿迪达斯官网adidas DURAMO 9男鞋跑步运动鞋BB7066黑色',
                    //     imgSrc:'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=98240891,2735753190&fm=11&gp=0.jpg',
                    //     jdPrice:210.00,
                    //     discountsPrice:''
                    // },
                    // {
                    //     shopName:'阿迪达斯官网adidas DURAMO 9男鞋跑步运动鞋BB7066黑色',
                    //     imgSrc:'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=98240891,2735753190&fm=11&gp=0.jpg',
                    //     jdPrice:210.00,
                    //     discountsPrice:''
                    // }
                ],
                isBtn: true,
                isActive: 1,
                selectionList: [],
            }
        },
        mounted() {
            this.promiseAll()
        },
        computed: {
            // 计算套装促销价
            promotionNum() {
                let total = 0;
                let promotionItem = 0;
                if (this.selectionList.length > 0) {
                    this.selectionList.forEach(item => {
                        // promotionItem = Number(item.goods_data.price) - Number(item.yh_sum)
                        promotionItem = this.calculate(Number(item.goods_data.price), Number(item.yh_sum), '-')
                        // total += promotionItem
                        total = this.calculate(total, promotionItem, '+')
                    })
                }
                return total
            },
            // 计算共计优惠价格
            discountsNum() {
                let total = 0;
                if (this.selectionList.length > 0) {
                    this.selectionList.forEach(item => {
                        // total += Number(item.yh_sum)
                        total = this.calculate(total, Number(item.yh_sum), '+')
                    })
                }
                return total
            },
            menus() {
                return this.$store.getters.dataMenuList;
            }
        },
        methods: {
            ...mapActions([
                'setDataMenusList'
            ]),
            // 更新状态
            operation_updateStatus(index1, index2,  status) {
                let param = {
                    offset1: Number(index1),
                    offset2: Number(index2),
                    status: Number(status),
                }
                this.$http.axiosGetBy(this.$api.data_setUserOpList, param, (res) => {
                    if (res.code === 200) {
                        this.setDataMenusList();
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            promiseAll() {
                const promise1 = this.getEditSuit()
                const promise2 = this.getGoodsList()
                Promise.all([promise1, promise2]).then((response) => {
                    if (response[0] !== null) {
                        this.suitDetail.name = response[0].data.data[0].stu_market_data[0].name
                        this.suitDetail.type = response[0].data.data[0].stu_market_data[0].type
                        let arrTmp = response[0].data.data[0].stu_market_data[0].activity_data.data
                        this.selectionList = arrTmp.map(item => {
                            item.errorPrompt = false
                            return item
                        })
                        this.selectionList.forEach(row => {
                            this.$refs.multipleTable.toggleRowSelection(row)
                        })
                    }

                    let resTmp = response[1].data.data[0].goods_list
                    for (let i = 0; i < resTmp.length; i++) {
                        let goodItemTmp = {
                            yh_sum: void 0,
                            // yh_sum: 0,
                            errorPrompt: false,
                        }
                        goodItemTmp.goods_data = {
                            id: resTmp[i].id,
                            name: resTmp[i].name,
                            image: resTmp[i].image.url,
                            price: Number(resTmp[i].price),
                            inventory: resTmp[i].inventory,
                        }
                        this.suitDetail.activity_data.push(goodItemTmp)
                    }

                    if (response[0] !== null) {
                        let selectArr = JSON.parse(JSON.stringify(this.selectionList))
                        selectArr.forEach(item1 => {
                            this.suitDetail.activity_data.forEach(item2 => {
                                if (item1.goods_data.id === item2.goods_data.id) {
                                    item2.yh_sum = item1.yh_sum
                                }
                            })
                        })
                    }
                });
            },
            // 获取编辑信息
            getEditSuit() {
                return new Promise((resolve, reject) => {
                    if (this.suitId) {
                        this.$http.axiosGetBy(this.$api.showStuMarket, {id: this.suitId, type: 3}, (res) => {
                            if (res.code === 200) {
                                resolve(res)
                            } else {
                                this.$message.warning(res.msg)
                                reject()
                            }
                        }, (err) => {
                            console.log(err)
                            reject()
                        })
                    } else {
                        resolve(null)
                    }
                })
            },
            // 获取商品列表
            getGoodsList() {
                return new Promise((resolve, reject) => {
                    this.$http.axiosGetBy(this.$api.showStoreMarket, {type: 3}, (res) => {
                        if (res.code === 200) {
                            resolve(res)
                        } else {
                            this.$message.warning(res.msg)
                            reject()
                        }
                    }, (err) => {
                        console.log(err)
                        reject()
                    })
                })
            },
            // 优惠金额输入
            watchDiscounts(val, id){
                this.selectionList.forEach(item => {
                    if (item.goods_data.id === id) {
                        item.yh_sum = Number(val)
                    }
                })
            },
            // 表格多选
            handleSelectionChange(selection){
                this.selectionList = selection
                if(selection.length > 1){
                    this.isBtn = false;
                    this.isActive = 2;
                } else {
                    this.isBtn = true;
                    this.isActive = 1;
                }
            },
            // 勾选单个商品
            selectOne(selection, row) {
                if (row.yh_sum === undefined) {
                    row.yh_sum = 0
                }
            },
            toBack(){
                this.$router.go(-1)
            },
            toNext(){
                let arrTmp = []
                let objTmp = {
                    type: 3,
                }
                if (this.suitDetail.name === '') {
                    return this.$message.warning('请输入促销名称')
                }
                if (this.selectionList.length < 2) {
                    return this.$message.warning('请选择商品')
                }
                this.selectionList = this.selectionList.map(item => {
                    if (item.yh_sum <= 0 || item.yh_sum >= Number(item.goods_data.price)) {
                        item.errorPrompt = true
                    } else {
                        item.errorPrompt = false
                    }
                    return item
                })
                for (let i = 0; i < this.selectionList.length; i++) {
                    let item = this.selectionList[i]
                    if (item.yh_sum <= 0 || item.yh_sum >= Number(item.goods_data.price)) {
                        this.$message.warning('优惠金额应大于0且小于商品价')
                        return
                    }
                }
                for (let i = 0; i < this.selectionList.length; i++) {
                    let item = this.selectionList[i]
                    delete item.errorPrompt
                    arrTmp.push(item)
                }
                objTmp.data = arrTmp
                let formData = new FormData()
                formData.append('type', objTmp.type)
                formData.append('name', this.suitDetail.name)
                formData.append('activity_data', JSON.stringify(objTmp))
                this.$http.axiosPost(this.$api.saveStudentMarket, formData, (res) => {
                    if (res.code === 200) {
                        this.$message.success(res.msg)
                        this.$router.push('/student/operationpromotion/setpromotion')
                        if (Number(this.$lockr.get('competition_type')) === 1) {
                            let menus = this.menus;
                            if (Number(menus[1].children[2].status) !== 2) {
                                this.operation_updateStatus(1,2,2);
                            }
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // js计算精度丢失
            toInteger(floatNum) {
                const ret = { times: 1, num: 0 }
                const isNegative = floatNum < 0
                if (Math.floor(floatNum) === floatNum) {
                    ret.num = floatNum
                    return ret
                }
                const strfi = String(floatNum)
                const dotPos = strfi.indexOf('.')
                const len = strfi.substr(dotPos + 1).length
                const times = Math.pow(10, len)
                let intNum = parseInt(Math.abs(floatNum) * times + 0.5, 10)
                ret.times = times
                if (isNegative) {
                    intNum = -intNum
                }
                ret.num = intNum
                return ret
            },
            calculate(a, b, op) {
                const o1 = this.toInteger(Number(a))
                const o2 = this.toInteger(Number(b))
                const n1 = o1.num
                const n2 = o2.num
                const t1 = o1.times
                const t2 = o2.times
                const max = t1 > t2 ? t1 : t2
                let result = null
                switch (op) {
                    case '+':
                        if (t1 === t2) {
                            // 两个小数位数相同
                            result = n1 + n2
                        } else if (t1 > t2) {
                            // o1 小数位 大于 o2
                            result = n1 + n2 * (t1 / t2)
                        } else {
                            // o1 小数位 小于 o2
                            result = n1 * (t2 / t1) + n2
                        }
                        return result / max
                    case '-':
                        if (t1 === t2) {
                            result = n1 - n2
                        } else if (t1 > t2) {
                            result = n1 - n2 * (t1 / t2)
                        } else {
                            result = n1 * (t2 / t1) - n2
                        }
                        return result / max
                    case '*':
                        result = (n1 * n2) / (t1 * t2)
                        return result
                    case '/':
                        result = (n1 / n2) * (t2 / t1)
                        return result
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    /deep/.redInput .el-input__inner{
        color: #FD4446;
    }
    /deep/.el-table__header-wrapper .el-checkbox .el-checkbox__input .el-checkbox__inner{
        display: none;
    }
    /deep/.el-checkbox .is-checked .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }
    /deep/.is-indeterminate .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }
    /deep/.el-button--default:hover{
        border-color: #FD4446;
        color: #FD4446;
        background: #ffffff;
    }
    .SetPromotionAdd{
        .SetPromotionAddHeader{
            p{
                line-height: 1;
                padding-top: 18px;
                color: #343434;
                font-size: 18px;
                font-weight: bold;
            }
        }
        .SetPromotionAddData{
            margin-top: 18px;
            background: #ffffff;
            padding: 20px;
            .bannerHeader{
                margin-top: 30px;
                display: flex;
                img{
                    margin-top: 20px;
                }
                display: flex;
                justify-content: center;
                .bannerHeaderOne{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    span{
                        display: inline-block;
                        width: 48px;
                        height: 48px;
                        background: #DCDCDC;
                        color: #ffffff;
                        font-size: 24px;
                        text-align: center;
                        line-height: 48px;
                        border-radius: 50%;
                    }
                    .isActive{
                        background: #FD4446;
                    }
                    b{
                        font-size: 16px;
                        font-weight: 400;
                        color: #333333;
                        line-height: 1;
                        margin-top: 20px;
                    }
                }
            }
        }
        .SetPromotionAddFill{
            background: #ffffff;
            padding: 20px 30px;
        }
    }
    .SetPromotionOne{
        .SetPromotionOneHeader{
            color: #343434;
            line-height: 1;
            .title{
                font-size: 18px;
            }
            .menuName{
                margin-top: 30px;
                span{
                    font-size: 16px;
                }
            }
            .menuShop{
                margin-top: 30px;
                span:nth-child(1){
                    font-size: 16px;
                }
                i{
                    font-size: 14px;
                    color: #FF9800;
                    margin: 0 10px;
                }
                .span{
                    font-size: 14px;
                }
            }
        }
        .SetPromotionOneTable{
            margin-top: 30px;
            .shopData{
                display: flex;
                align-items: center;
                /*justify-content: center;*/
                img{
                    width: 48px;
                    height: 48px;
                }
                span{
                    color: #333333;
                    font-size: 14px;
                    margin-left: 10px;
                    display: flex;
                    flex-direction: column;
                    b{
                        font-weight: 500;
                        display: inline-block;
                        width: 30px;
                        border: 1px solid #39CD7C;
                        color: #39CD7C;
                        font-size: 10px;
                        /*padding: 3px 6px;*/
                        height: 16px;
                        text-align: center;
                        line-height: 16px;
                    }
                }
            }
        }
        .SetPromotionOneText{
            display: flex;
            justify-content: space-between;
            line-height: 1;
            .text{
                margin-top: 20px;
                .text1{
                    span:nth-child(1){
                        color: #343434;
                        font-size: 18px;
                    }
                    span:nth-child(2){
                        font-size: 14px;
                        color: #FE4447;
                        margin-left: 12px;
                    }
                    span:nth-child(3){
                        font-size: 24px;
                        color: #FE4447;
                    }
                }
                .text2{
                    margin-top: 20px;
                    color: #9A9A9A;
                    font-size: 16px;
                }
            }
        }
        .SetPromotionOneBtn{
            margin-top: 44px;
            .btn{
                width: 100px;
                height: 40px;
                line-height: 0;
            }
            /*.btn:nth-child(2){*/
            /*    background: #FD4446;*/
            /*    border-color: #FD4446;*/
            /*    color: #ffffff;*/
            /*}*/
            span{
                color: #FE4447;
                font-size: 14px;
                margin-left: 20px;
            }
        }
    }
    /deep/ .el-input {
        &.is-error {
            .el-input__inner {
                border-color: #FD4446;
            }
        }
    }
    /deep/ .el-input-number {
        .el-input__inner {
            text-align: left;
        }
        &.isError {
            .el-input__inner {
                border-color: #FD4446;
            }
        }
    }
</style>